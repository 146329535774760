$cultured: hsla(210, 17%, 98%, 1);
$cultured-2: hsla(210, 16%, 93%, 1);
$gainsboro: hsla(210, 14%, 89%, 1);
$light-gray: hsla(210, 14%, 83%, 1);
$cadet-blue-crayola: hsla(210, 11%, 71%, 1);
$slate-gray: hsla(208, 7%, 46%, 1);
$davys-grey: hsla(210, 9%, 31%, 1);
$gunmetal: hsla(210, 10%, 23%, 1);
$charleston-green: hsla(210, 11%, 15%, 1);
$google-blue: #4285f4;
$github-white: #fafafa;
$github-black: #333;
$facebook-blue: #3b5998;
$twitter-blue: #1da1f2;
