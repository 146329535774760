@import "../../Components/Varibles.scss";
.nav__homepage {
  height: 5vh;
  width: 100%;
  background-color: $charleston-green;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  @media (max-width: 600px) {
    padding: 0.3rem 1rem;
  }
  &--btn {
    height: 90%;
    border-radius: 5rem;
    width: 10rem;
    border: none;
    background-color: transparent;
    border: 1px solid $cultured-2;
    color: $cultured-2;
    font-family: "Gudea", sans-serif;
    cursor: pointer;
    font-size: 2rem;
    transition: 0.3s all ease-out;
    margin-right: 1rem;
    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
    &:hover {
      background-color: $cultured-2;

      color: $charleston-green;
    }
  }
}
.homepage__container {
  height: 95vh;
  width: 100vw;
  background-color: $gunmetal;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Gudea", sans-serif;

  .heading {
    font-size: 6rem;
    display: flex;
    width: 100vw;
    font-family: "Vollkorn", serif;
    justify-content: center;
    color: $gainsboro;
  }
  .createPen__container {
    max-width: 70rem;
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: 400px) {
      flex-direction: column;
      padding: 0 1rem;
    }
    .createPen-input {
      background-color: $gunmetal;
      color: $gainsboro;
      height: 100%;
      max-width: 40rem;
      width: 100%;
      font-size: 2.5rem;
      padding: 0.5rem;
      border: 2px solid $gainsboro;
      border-right: none;
      border-radius: 1.5rem 0 0 1.5rem;
      transition: all 0.3s ease;
      font-family: "Gudea", sans-serif;

      @media (max-width: 400px) {
        border-radius: 1.5rem;
        border: 2px solid $gainsboro;
      }
      &::placeholder {
        color: $gainsboro;
      }
      &:focus {
        background-color: $gainsboro;
        color: $gunmetal;
        outline: none;
        &::placeholder {
          color: $gunmetal;
        }
      }
      &--btn {
        height: 100%;
        background-color: $gainsboro;
        color: $gunmetal;
        padding: 1rem;
        font-size: 2rem;
        cursor: pointer;
        transition: 0.5s all ease;
        border: 2px solid $gainsboro;
        border-radius: 0 1.5rem 1.5rem 0;
        font-family: "Gudea", sans-serif;
        &:hover,
        &:focus {
          background-color: transparent;
          border: 2px solid $gainsboro;
          border-left: none;
          color: $gainsboro;
          @media (max-width: 400px) {
            border-left: 2px solid $gainsboro;
          }
        }
        @media (max-width: 400px) {
          margin-top: 1rem;
          border-radius: 1.5rem;
        }
      }
    }
  }

  .pen {
    &__container {
      max-width: 80rem;
      width: 100%;
      border: 2px solid $gainsboro;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      max-width: 50rem;
      margin-top: 3rem;

      .pen {
        width: 100%;
        border-bottom: 2px solid $gainsboro;
        display: flex;

        justify-content: space-between;
        &__text {
          padding: 1rem;
          font-size: 2rem;
          color: $gainsboro;
          text-decoration: none;
          &:hover {
            color: $cultured-2;
          }
        }
        &__btn {
          height: 100%;
          max-width: 10rem;
          width: 100%;
          background-color: transparent;
          border-right: 1px solid $gainsboro;
          border: none;
          font-size: 2rem;
          cursor: pointer;
          transition: all 0.3s ease-in;
          color: $gainsboro;
          &:hover {
            border-right-color: transparent;
            background-color: #ee6352;
          }
        }
      }
    }
  }
  .pen:last-of-type {
    border: none;
  }
}
