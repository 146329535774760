@import "../Varibles.scss";
.editor {
  &-container {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: hsl(225, 6%, 25%);
    transition: all 1s ease-in-out;
    .code-mirror-wraper {
      flex-grow: 1;
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      overflow: hidden;
      .CodeMirror {
        height: 100% !important;
        font-size: 1.7rem;
        font-family: "Martel", serif;
      }
    }
  }
  &-title {
    display: flex;
    justify-content: space-between;
    background-color: hsl(225, 6%, 13%);
    color: #fff;
    font-family: "Vollkorn", serif;
    font-size: 2.5rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    .expand-btn {
      margin-left: 0.5rem;
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
    }
  }
}
.collapsed {
  flex-grow: 0;
  .CodeMirror-scroll {
    position: absolute;
    overflow: hidden;
  }
}
.cm-s-default {
  background-color: #36373b !important;
  color: white !important;
}
.nav {
  &__editor {
    background-color: $charleston-green;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5vh;
    padding: 1rem;
    @media (max-width: 600px) {
      flex-direction: column;
      height: 100%;
    }
    &--left-items {
      display: flex;
      align-items: center;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      .nav__item {
        margin-left: 2rem;
        font-size: 2.5rem;
        color: $light-gray;

        &--username {
          color: $cultured;
          font-family: "Vollkorn", serif;
        }
      }
      #logo {
        display: block;
        fill: none;
        stroke: $light-gray;
        stroke-width: 2.3;
        stroke-linejoin: round;
        stroke-linecap: round;
        height: 100%;
        width: 15rem;
        &:hover {
          stroke: $cultured-2;
        }
      }
    }
    .right-items {
      height: 5vh;
      padding: 0.2rem;
      .btn {
        height: 90%;
        border-radius: 5rem;
        width: 10rem;
        border: none;
        background-color: transparent;
        border: 1px solid $cultured-2;
        color: $cultured-2;
        cursor: pointer;
        font-size: 2rem;
        transition: 0.3s all ease-out;
        font-family: "Gudea", sans-serif;

        margin-right: 1rem;
        &:hover {
          background-color: $cultured-2;

          color: $charleston-green;
        }
      }
    }
  }
}
