@import "../Varibles.scss";
.signin {
  font-family: "Gudea", sans-serif;
  &__heading {
    font-size: 5rem;
    color: $cultured-2;
    margin-bottom: 3rem;
    font-family: "Vollkorn", serif;
  }
  &__form {
    max-width: 50rem;
    width: 100%;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    &--container {
      background-color: $charleston-green;
      height: 95vh;
      width: 100vw;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__input {
    display: block;

    width: 100%;
    height: 100%;
    border: none;
    padding: 1rem;
    font-size: 2.3rem;
    border-radius: 3rem;
    background-color: $gainsboro;
    color: $gunmetal;
    transition: all 0.2s ease-out;
    &::placeholder {
      color: $gunmetal;
    }
    &:active,
    &:focus {
      outline: none;
      background-color: $cultured;
    }
    &--label {
      display: inline-block;
      height: 5rem;
      width: 100%;
      margin-bottom: 3rem;
    }
  }
  &__btn {
    height: 6rem;
    border-radius: 3rem;
    width: 100%;
    font-size: 2.5rem;
    background-color: transparent;
    border: none;
    font-family: "Gudea", sans-serif;

    cursor: pointer;
    transition: all 0.3s ease-out;
    margin-bottom: 3rem;
    &--google {
      border: 3px solid $google-blue;
      color: $google-blue;
      &:hover {
        background-color: $google-blue;
        color: $cultured;
      }
      &:focus {
        color: #fff;
        outline: none;
      }
    }
    &--github {
      border: 3px solid $github-white;
      color: $github-white;
      &:hover {
        background-color: $github-black;
      }
      &:focus {
        color: #fff;
        outline: none;
      }
    }
    &--facebook {
      border: 3px solid $facebook-blue;
      color: $google-blue;
      &:hover {
        background-color: $facebook-blue;
        color: $cultured;
      }
      &:focus {
        color: #fff;
        outline: none;
      }
    }
    &--twitter {
      border: 3px solid $twitter-blue;
      color: $twitter-blue;
      &:hover {
        background-color: $twitter-blue;
        color: $cultured;
      }
      &:focus {
        color: #fff;
        outline: none;
      }
    }
  }
}

.nav__signin {
  background-color: $charleston-green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5vh;
  padding: 1rem;
  &--newPen {
    font-size: 2rem;
    color: $light-gray;
    text-decoration: none;
    transition: all 0.2s ease-in;
    &:hover,
    &:focus {
      color: $gainsboro;
    }
  }
  &--logo {
    display: block;
    fill: none;
    stroke: $light-gray;
    stroke-width: 2.3;
    stroke-linejoin: round;
    stroke-linecap: round;
    height: 100%;
    width: 150px;
  }
}
